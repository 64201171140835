html,body{
    margin:0;
    }

.SplashContainer{
    background: url(../../assets/images/hexyBackground.png);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    min-height: 82vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-left: 5rem;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.background-tint{
    background-color: rgba(12, 7, 3, 0.672);
    background-blend-mode: multiply;
}

.SplashContactButton{
    background: var(--primary4);
}

.DescriptionSplash > p {
    font-size: 1.1rem;
    line-height: 1.8rem;
}

.ContactNowContainer{
    font-size: larger;
    background: var(--primary2);
    color: black;
}

.SplashHeader{
    max-width: 80%;
    padding-bottom: 2rem;
    border-bottom: solid white;
}

.SplashTextButtonContainer{
    margin-top: 1.2rem;
    display: flex;
}

.SplashTaglineContainer{
    max-width: 55rem;
}

.SplashTaglineContainer > h2 {
    font-size: 1.2rem;
}

.ContactNowContainer > a >button {
    background: var(--primary4);
    color: white;
}

.row {
    display: flex;
    text-align: center;
}

.column {
    flex: 50%;
}

.testing{
    background-color: black;
    width: 100%;
    height: 100%;
}

.QuoteCardContainer{
    display: flex;
    justify-content: center;
}

.QuoteCard{
    width: 22rem;
    background: var(--primary2);
}

@media(max-width: 1150px){
    .SplashContainer{
        padding-left: 0;
    }
    .SplashTextButtonContainer{
        flex-direction: column;
        padding-right: 2rem;
        padding-left: 2rem;
    }
    .SplashContactButton{
        margin-top: 1rem;
    }
}

@media (max-width: 700px) {

    .SplashContainer{
        text-align: center;
        align-items: center;
    }

    .SplashHeader{
        border-bottom: none;
    }

    .row{
        flex-direction: column;
      }
    .DescriptionSplash{
        margin-top: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}


