.AllCardsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.WhyCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    color: black;
    text-align: center;
    width: 20rem;
    border-radius: 2rem;
    padding-left:2rem;
    padding-right: 2rem;
    margin: 2rem;
}

.WhyHeader{
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: bold;
    font-size: 1.3rem;
}

.WhyDescription{
    padding-bottom: 1rem;
}